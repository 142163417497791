import React, { Component } from 'react';
import TreeUtils from '../../utils/TreeUtils';
import './Table.css';

class HeaderTable extends Component {

    state = {
        expand: false
    }

    buildHeader() {
        if (!this.props.baoCaoNgay) return '';
        let cols = TreeUtils.flatTree(this.props.baoCaoNgay.colConfig)
        let array = [];
        this.wIndex = 2;
        for (let i = 0; i < this.props.baoCaoNgay.colConfig.maxRowLevel; i++) {
            array.push(this.makeHeaderRows(cols, i, this.props.baoCaoNgay.colConfig.maxRowLevel));
        }
        return array;
    }

    toggleAll(ev) {
        let expand = !this.state.expand;
        this.setState({ expand })
        if (this.props.toggleAll) this.props.toggleAll(expand);
    }

    makeHeaderRows(cols, i, rowSpan) {
        let rowCols = cols.filter(q => q.code.split('.').length == i + 1);
        return (
            <tr>
                {
                    i == 0 ?
                        (
                            <>
                                <th className='stick-column'
                                    rowSpan={rowSpan}
                                    style={{
                                        minWidth: this.props.baoCaoNgay.colConfig.widths[0] / 3 + 'rem', left: '0px',
                                        maxWidth: this.props.baoCaoNgay.colConfig.widths[0] / 3 + 'rem', left: '0px',
                                    }}>
                                    <button onClick={(ev) => this.toggleAll(ev)} style={{ width: "30px" }}>
                                        {this.state.expand ? "-" : "+"}
                                    </button>
                                </th>
                                <th className='stick-column'
                                    rowSpan={rowSpan}
                                    style={{
                                        minWidth: this.props.baoCaoNgay.colConfig.widths[0] + 'rem', left: this.props.baoCaoNgay.colConfig.widths[0] / 3 + 'rem',
                                        maxWidth: this.props.baoCaoNgay.colConfig.widths[0] + 'rem', left: this.props.baoCaoNgay.colConfig.widths[0] / 3 + 'rem'
                                    }}>
                                    {!this.props.isImportPB ? "STT" : "Mã phòng ban"}
                                </th>
                                <th className='stick-column'
                                    rowSpan={rowSpan}
                                    style={{
                                        minWidth: this.props.baoCaoNgay.colConfig.widths[1] / 2.5 + 'rem', left: this.props.baoCaoNgay.colConfig.widths[0] * 4 / 3 + 'rem',
                                        maxWidth: this.props.baoCaoNgay.colConfig.widths[1] / 2.5 + 'rem', left: this.props.baoCaoNgay.colConfig.widths[0] * 4 / 3 + 'rem',
                                    }}>
                                    Tên Phòng Ban
                                </th>
                                {
                                    this.props.isImportPB ? <th>Phân quyền</th> : ""
                                }
                            </>
                        )
                        :
                        ""
                }
                {
                    this.props.isImportPB ?
                        ""
                        :
                        rowCols.map(q => {
                            if (!q.hasChild) {
                                this.wIndex++;
                            }
                            return (
                                <th key={"col" + q.code}
                                    rowSpan={q.room[1]} colSpan={q.room[0]}
                                    style={{
                                        color: "#" + q.style.font.color.argb.replace('FF', ''),
                                        backgroundColor: "#" + q.style.fill.fgColor.argb.replace('FF', ''),
                                        minWidth: this.props.baoCaoNgay.colConfig.widths[this.wIndex] / 2.5 + 'rem'
                                    }}>
                                    {q.name}
                                </th>
                            );
                        })
                }
            </tr>
        );
    }

    render() {
        const header = this.buildHeader();
        return (
            <thead>
                {header}
            </thead>
        );
    }
}

export default HeaderTable;