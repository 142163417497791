import React, { Component } from 'react';
import TreeUtils from '../../utils/TreeUtils';
import Cons from '../../utils/Cons';

class BodyTable extends Component {

    state = {
        data: {},
        expands: [],
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.toggleAll != nextProps.toggleAll) {
            let expands = this.state.expands.map(q => nextProps.toggleAll);
            nextState.expands = expands;
        }
        // nhập dữ liệu
        if (this.props.baoCaoNgay !== nextProps.baoCaoNgay) {
            nextState.expands = [];
            if (nextProps.rowConfArray && nextProps.rowConfArray.length) {
                for (let i = 0; i < nextProps.rowConfArray.length; i++) {
                    nextState.expands.push(false);
                }
            }
            nextState.data = nextProps.baoCaoNgay.data;
        }
        return true;
    }

    makeRowInput(rIndex, keys) {
        const rowConf = this.props.rowConfArray[rIndex];
        let hasRowValue = keys.filter(q => q.includes(rowConf.key)).length > 0;
        const rowArray = [];
        for (let i = 0; i < this.props.baoCaoNgay.colConfig.maxColLevel; i++) {
            let colConf = this.props.colConfArray[i];
            if (!hasRowValue && i === 0) {
                rowArray.push(<td className='alert' colSpan={this.props.baoCaoNgay.colConfig.maxColLevel - 1}>Chưa nhập dữ liệu</td>);
                continue;
            }
            if (!hasRowValue && i !== 0 && colConf.cType !== 'TEXT') {
                continue;
            }

            const isFunc = !!colConf.func;
            const isPercent = isFunc && colConf.func.includes('p');
            let key = colConf.key + " " + rowConf.key
            let value = this.state.data[key];
            if (!value) value = '';
            if (value && isPercent) {
                value = parseInt(value * 10000) / 100 + "%";
            }
            if (colConf.cType === 'TEXT') {
                rowArray.push(
                    <td>
                        <input
                            onChange={(ev) => this.changeCommand(ev.target.value, i, rIndex)}
                            value={value} disabled={(isFunc || rowConf.hasChild) && colConf.cType !== Cons.C_TYPE[0]}
                            type='text' size="6" />
                    </td>);
            }
            else {
                rowArray.push(
                    <td>
                        {value}
                    </td>);
            }
        }
        return rowArray;
    }

    changeExpand(code) {
        const expands = [...this.state.expands];
        const index = TreeUtils.findIndexByCode(this.props.rowConfArray, code);
        expands[index] = !this.state.expands[index];
        this.setState({
            expands,
        });
    }

    makeBody() {
        if (!this.props.baoCaoNgay) return "";

        let arr = [];
        let rIndex = 0;
        let keys = Object.keys(this.props.baoCaoNgay.data);

        let rShowIndex = 0;
        for (let row of this.props.rowConfArray) {
            let parentIndexes = TreeUtils.findAllParent(this.props.rowConfArray, row.code, 0);
            let visible = true;
            parentIndexes = parentIndexes.filter((pRowIndex, index, arr) => {
                return this.state.expands[pRowIndex] === false && this.props.rowConfArray[pRowIndex].forAccounts.indexOf(this.props.user.name) > -1
            }
            );
            visible = parentIndexes.length > 0 ? false : true;
            if (!this.props.importData) {
                visible = visible && (row.forAccounts.indexOf(this.props.user.name) > -1);
            }

            if (visible) {
                let rowValue = this.makeRowInput(rIndex, keys);

                let color = Cons.ColorLevels[row.code.split('.').length];
                if (!color || !row.hasChild) color = '#FFFFFF';
                arr.push(
                    (
                        (<tr style={{ backgroundColor: color }}>
                            <td className='stick-column' style={{ left: '0px' }}>
                                {
                                    row.hasChild ?
                                        <button onClick={(ev) => this.changeExpand(row.code)} style={{
                                            width: "30px"
                                        }}>
                                            {this.state.expands[rIndex] ? "-" : "+"}
                                        </button>
                                        :
                                        ""
                                }
                            </td>
                            <th
                                style={{ left: this.props.baoCaoNgay.colConfig.widths[0] / 3 + 'rem' }}
                                className='code stick-column'>
                                {row.code}
                            </th>
                            <th
                                style={{ left: this.props.baoCaoNgay.colConfig.widths[0] * 4 / 3 + 'rem' }}
                                className='pb-name stick-column'>
                                {row.name}
                            </th>
                            {rowValue}
                        </tr >)
                    )
                )
                rShowIndex++;
            }
            rIndex++;
        }
        return arr;
    }

    changeCommand = (newValue, colIndex, rowIndex) => {
        const newData = { ...(this.state.data) };
        const colConf = this.props.colConfArray[colIndex];
        let key = colConf.key + " " + this.props.rowConfArray[rowIndex].key;
        newData[key] = newValue;

        this.setState({ data: newData });
        if (!this.updateData) {
            this.updateData = {};
        }
        this.updateData[key] = newValue;
    }

    render() {
        const body = this.makeBody();
        return (<tbody>
            {body}
        </tbody>)
    }
}

export default BodyTable;