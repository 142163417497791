import React, { Component } from 'react';
import TreeUtils from '../../utils/TreeUtils';
import ReCalUtil from '../../utils/RecalUtil';
import Cons from '../../utils/Cons';
import './Table.css'

class BodyTable extends Component {

    state = {
        data: {},
        expands: [],
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.toggleAll != nextProps.toggleAll) {
            let expands = this.state.expands.map(q => nextProps.toggleAll);
            nextState.expands = expands;
        }
        // import dữ liệu
        if (nextProps.importData) {
            nextState.data = nextProps.importData.excelData;
            nextState.expands = [];
            if (nextProps.rowConfArray && nextProps.rowConfArray.length) {
                for (let i = 0; i < nextProps.rowConfArray.length; i++) {
                    nextState.expands.push(true);
                }
            }
            return true;
        }
        // nhập dữ liệu
        if (this.props.baoCaoNgay !== nextProps.baoCaoNgay) {
            nextState.expands = [];
            if (nextProps.rowConfArray && nextProps.rowConfArray.length) {
                for (let i = 0; i < nextProps.rowConfArray.length; i++) {
                    nextState.expands.push(false);
                }
            }
            nextState.data = nextProps.baoCaoNgay.data;
        }
        return true;
    }

    makeRowInput(rIndex, rShowIndex) {
        const rowConf = this.props.rowConfArray[rIndex];
        const rowArray = [];
        for (let i = 0; i < this.props.baoCaoNgay.colConfig.maxColLevel; i++) {
            let colConf = this.props.colConfArray[i];
            const isFunc = !!colConf.func;
            const isPercent = isFunc && colConf.func.includes('p');
            let key = colConf.key + " " + rowConf.key
            let value = this.state.data[key];
            if (!value) value = '';
            if (value && isPercent) {
                value = parseInt(value * 10000) / 100 + "%";
            }
            if (this.props.importData) {
                const isError = this.props.importData.errors ? this.props.importData.errors[key] : false
                rowArray.push(
                    <td
                        style={{
                            background: isError ? 'red' : 'transparent'
                        }}>
                        {value}
                    </td>);
                continue;
            }
            if (isFunc) {
                rowArray.push(
                    <td className='text-center'>
                        <input
                            onBlur={(ev) => this.changeData(ev.target.value, i, rIndex)}
                            value={value} disabled={isFunc || rowConf.hasChild}
                            type='text' size="6"
                        />
                    </td>);
                continue;
            }
            rowArray.push(
                <td className='text-center'>
                    <input
                        onChange={(ev) => this.changeData(ev.target.value, i, rIndex)}
                        value={value} disabled={(isFunc || rowConf.hasChild) && colConf.cType !== Cons.C_TYPE[0]}
                        type='text' size="6"
                    />
                </td>);
        }
        return rowArray;
    }

    makeError(rShowIndex) {
        if (this.props.importData) {
            let codeError = this.props.importData.rowErrors["0 " + rShowIndex];
            codeError = codeError ? codeError.message : "";
            let nameError = this.props.importData.rowErrors["1 " + rShowIndex];
            nameError = nameError ? nameError.message : "";
            let forUserError = this.props.importData.rowErrors["2 " + rShowIndex];
            forUserError = forUserError ? forUserError.message : "";
            return (
                <td>
                    {codeError}
                    {nameError}
                    {forUserError}
                </td>
            )
        }
        return "";
    }

    changeExpand(code) {
        const expands = [...this.state.expands];
        const index = TreeUtils.findIndexByCode(this.props.rowConfArray, code);
        expands[index] = !this.state.expands[index];
        this.setState({
            expands,
        });
    }

    makeBody() {
        if (!this.props.baoCaoNgay) return "";

        let arr = [];
        let rIndex = 0;

        let rShowIndex = 0;
        for (let row of this.props.rowConfArray) {
            let parentIndexes = TreeUtils.findAllParent(this.props.rowConfArray, row.code, 0);
            let visible = true;
            parentIndexes = parentIndexes.filter((pRowIndex, index, arr) => {
                return this.state.expands[pRowIndex] === false && this.props.rowConfArray[pRowIndex].forAccounts.indexOf(this.props.user.name) > -1
            }
            );
            visible = parentIndexes.length > 0 ? false : true;
            if (!this.props.importData) {
                visible = visible && (row.forAccounts.indexOf(this.props.user.name) > -1);
            }

            if (visible) {
                let rowValue = null;
                if (!this.props.isImportPB) {
                    rowValue = this.makeRowInput(rIndex, rShowIndex);
                }
                let color = Cons.ColorLevels[row.code.split('.').length];
                if (!color || !row.hasChild) color = '#FFFFFF';

                let codeError = 'inherit';
                let nameError = 'inherit';
                let forUserError = 'inherit';

                if (this.props.importData) {
                    codeError = this.props.importData.rowErrors["0 " + rShowIndex];
                    if (codeError) {
                        codeError = codeError.pri === 0 ? "red" : 'yellow';
                    }
                    nameError = this.props.importData.rowErrors["1 " + rShowIndex];
                    if (nameError) {
                        nameError = nameError.pri === 0 ? "red" : 'yellow';
                    }
                    forUserError = this.props.importData.rowErrors["2 " + rShowIndex];
                    if (forUserError) {
                        forUserError = forUserError.pri === 0 ? "red" : 'yellow';
                    }
                }
                console.log(row.name);
                arr.push(
                    (
                        (<tr style={{ backgroundColor: color }}>
                            <th className='stick-column' style={{ left: '0px' }} >
                                {
                                    row.hasChild ?
                                        <button onClick={(ev) => this.changeExpand(row.code)} style={{
                                            width: "30px"
                                        }}>
                                            {this.state.expands[rIndex] ? "-" : "+"}
                                        </button>
                                        :
                                        ""
                                }
                            </th>
                            <th
                                className='code stick-column'
                                style={{
                                    backgroundColor: codeError,
                                    left: this.props.baoCaoNgay.colConfig.widths[0] / 3 + 'rem'
                                }}>
                                {row.code}
                            </th>
                            <th
                                className='pb-name stick-column'
                                style={{
                                    left: this.props.baoCaoNgay.colConfig.widths[0] * 4 / 3 + 'rem',
                                    textAlign: 'left',
                                    backgroundColor: nameError
                                }}>
                                {row.name}
                            </th>
                            {this.props.isImportPB ?
                                (<td style={{ backgroundColor: forUserError }}>
                                    {row.forAccounts ? row.forAccounts.join(',') : ""}
                                </td>)
                                :
                                rowValue
                            }
                            {this.makeError(rShowIndex)}
                        </tr>)
                    )
                )
                rShowIndex++;
            }
            rIndex++;
        }
        return arr;
    }

    changeData = (newValue, colIndex, rowIndex) => {
        const recalUtil = new ReCalUtil(this.props.rowConfArray, this.props.colConfArray);
        if (!newValue) newValue = 0;
        if (!this.state.data) {
            this.state.data = {};
        }
        if (!this.updateData) {
            this.updateData = {};
        }
        let oldData = Object.assign({}, this.state.data);
        const newData = recalUtil.changeData(newValue, colIndex, rowIndex, oldData);
        let key = this.props.colConfArray[colIndex].key + " " + this.props.rowConfArray[rowIndex].key;
        this.updateData[key] = newValue;

        this.setState({ data: newData });
    }

    render() {
        const body = this.makeBody();
        return (<tbody>
            {body}
        </tbody>)
    }
}

export default BodyTable;