import React, { Component } from 'react';
import ImportDanhSach from '../utils/ImportDanhSach';
import DiemDanhService from '../services/DiemDanhService';
import dayjs from 'dayjs';
import importFileExcelBuilder from '../utils/createImportFile';
import { Box, Stack, Button, IconButton } from '@mui/material';
import { Error, ArrowCircleRight, Download, UploadFile, Save } from '@mui/icons-material'
const diemDanhService = new DiemDanhService();

class DiemDanhNgayPage extends Component {

    state = {
        importData: [],
        selectedDate: dayjs(new Date).format('YYYY-MM-DD'),
        phongbans: [],
        phongBan: null,
        rErrors: {},
        enableSave: false,
    }

    componentDidMount() {
        this.changeDate(this.state.selectedDate);
    }

    changeDate = (selectedDate) => {
        diemDanhService.getEnum().then((configs) => {
            diemDanhService.getPhongBan(selectedDate).then((phongbans) => {
                this.setState({ phongBan: phongbans[0].key })
                this.setState({ phongbans });
                ImportDanhSach.init(phongbans, configs);
            })
        });
    }

    importData = (ev) => {
        let file = ev.currentTarget.files[0];
        if (!file) return;
        ImportDanhSach.importExcel(file, this.loadDataImport);
    }

    loadDataImport = (dataImport) => {
        this.setState(dataImport);
        this.validatePreImport(dataImport);
        document.getElementById("file_input").value = "";
    }

    downLoadErrorFile = () => {
        let errors = { ...this.state.errors, ...this.state.rErrors }
        const excel = importFileExcelBuilder.build(null, this.state.importData, errors);
        excel.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "FileError" + dayjs(new Date()).format("_DD_MM_YYYY") + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
        })
    }

    validatePreImport(dataImport) {
        const enableSave = Object.keys(dataImport.errors).length == 0;
        if (!enableSave) {
            this.setState({ enableSave });
            return;
        }
        let data = {};
        data.list = dataImport.importData;
        data.date = this.selectedDate;

        diemDanhService.validate(data).then((rErrors) => {
            const enableSave = Object.keys(rErrors).length == 0;
            this.setState({ rErrors, enableSave });
        });
    }

    chooseFile = () => {
        document.getElementById('file_input').click();
    }

    saveImport = () => {
        let data = {};
        data.list = this.state.importData;
        data.date = this.state.selectedDate;
        data.phongBanKey = this.state.phongBan;

        diemDanhService.import(data).then((rErrors) => {
            window.location.href = "/";
        }).catch(() => {
            alert("Không lưu được điểm danh");
        })
    }

    downloadImportFile = () => {
        diemDanhService.getLastedImport({ date: this.state.selectedDate }).then((lastedImport) => {
            const excel = importFileExcelBuilder.build(null, lastedImport);
            excel.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer]);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = "FileImport" + dayjs(new Date()).format("_DD_MM_YYYY") + ".xlsx";
                a.click();
                window.URL.revokeObjectURL(url);
            })
        });
    }

    makeRow(rowData, rowIndex) {
        let dataMap = ImportDanhSach.dataMap.filter(q => !q.out);
        let keys = dataMap.map(q => q.feild);
        const cells = [];
        let colIndex = 0;
        let rowDupError = "";
        for (let key of keys) {
            // set background color by import errors
            let backgroundColor = this.state.errors[rowIndex + "_" + colIndex] ? 'red' : 'white';
            // set background color by response errors
            let value = rowData[key];
            if (key == 'empCode') {
                backgroundColor = this.state.rErrors[value] ? 'red' : backgroundColor;
                rowDupError = this.state.rErrors[value];
            }
            if (key == 'startDate') {
                value = value ? dayjs(value).format('DD/MM/YYYY') : '';
            }

            let colInfo = dataMap[colIndex];

            if (value && colInfo.format) {
                value = colInfo.format(value);
            }
            cells.push(<td style={{ backgroundColor }}>{!value ? "" : value.toString()}</td>);
            colIndex++;
        }
        let errorKeys = Object.keys(this.state.errors);
        errorKeys = errorKeys.filter(q => q.startsWith(rowIndex + "_"));
        if ((errorKeys && errorKeys.length > 0) || rowDupError) {
            let errorMessages = []
            for (let errorKey of errorKeys) {
                errorMessages.push(this.state.errors[errorKey]);
            }
            if (rowDupError) {
                errorMessages.push(rowDupError);
            }
            cells.push(<td>{errorMessages.join(', ')}</td>);
        } else {
            cells.push(<td></td>);
        }
        return cells;
    }

    makeBody() {
        const rows = [];
        let rowIndex = 1;
        for (let row of this.state.importData) {
            rows.push(<tr>
                {this.makeRow(row, rowIndex)}
            </tr>);
            rowIndex++;
        }
        return rows;
    }

    render() {
        let dataMap = ImportDanhSach.dataMap.filter(q => !q.out);
        return (
            <Box>
                <div style={{ margin: '1rem', height: 'calc(100vh - 70px - 64px - 1rem)', overflowY: 'auto' }}>
                    {
                        this.state.importData.length == 0 ?
                            <span>Không có dữ liệu - hãy tải file dữ liệu phía dưới</span>
                            :
                            (
                                <table id="imp_table">
                                    <thead>
                                        <tr>
                                            {dataMap.map(q => <th style={{ background: "#" + q.style.fill.fgColor.argb.replace('FF', '') }}>{q.name}</th>)}
                                            {
                                                !(this.state.enableSave || this.state.importData.length == 0) ? <th style={{ backgroundColor: 'red' }}> Lỗi phát sinh </th> : ""
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.makeBody()}
                                    </tbody>
                                </table>
                            )
                    }
                </div>

                <Stack direction='row' sx={{ background: 'white', boxShadow: 'rgba(17, 12, 46, 0.15) 0px -48px 100px 0px;', position: 'fixed', justifyContent: 'space-between', bottom: '0px', left: '0px', right: '0px', padding: '1rem' }}>
                    <Box>
                        <input
                            type="date"
                            max={dayjs(new Date()).format('YYYY-MM-DD')}
                            value={this.state.selectedDate}
                            onChange={(ev) => { this.setState({ selectedDate: ev.target.value }) }} />
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                        <input type='file' id='file_input' onChange={this.importData} style={{ display: 'none' }} />
                        <Button onClick={this.downloadImportFile} sx={{ fontSize: '14px' }} variant="contained" size='' color="success">
                            <Download sx={{ marginRight: '2px' }} />Tải file import
                        </Button>

                        <Button onClick={this.chooseFile} sx={{ fontSize: '14px', marginLeft: '15px' }} variant="contained" size='' color="success">
                            <UploadFile sx={{ marginRight: '2px' }} />Chọn file
                        </Button>

                        <Button disabled={this.state.enableSave || this.state.importData.length == 0} onClick={this.downLoadErrorFile} sx={{ fontSize: '14px', marginLeft: '15px' }} variant="contained" size='' color="success">
                            <Error sx={{ marginRight: '2px' }} />Tải file lỗi
                        </Button>

                        <Button disabled={!this.state.enableSave} onClick={this.saveImport} sx={{ fontSize: '14px', marginLeft: '15px' }} variant="contained" size='' color="success">
                            <Save sx={{ marginRight: '2px' }} />Lưu dữ liệu
                        </Button>
                    </Box>
                </Stack>
            </Box>
        );
    }
}

export default DiemDanhNgayPage;