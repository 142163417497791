import React, { Component } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { Link } from "react-router-dom";
import { StackedBarChart, Publish, AccountTree } from '@mui/icons-material';
const danhMuc = [
    {
        name: "TỔNG HỢP KẾT QUẢ ĐIỂM DANH",
        url: "/",
        icon: <StackedBarChart />
    },
    {
        name: "Import điểm danh",
        url: "/import",
        icon: <Publish />
    },
    {
        name: "Cấu trúc phòng ban",
        url: "/cautruc",
        icon: <AccountTree />
    }
]

class NavBar extends Component {

    state = {
    }

    render() {
        let url = window.location.href;
        let index = danhMuc.filter(q => url.endsWith(q.url));
        index = danhMuc.indexOf(index[0]);
        return (
            <Box sx={{ width: '100%', typography: 'body1', textAlign: 'right' }}>
                {
                    danhMuc.map((q, i) => (<Button variant={i == index ? "contained" : "outlined"} href={q.url} color="success" style={{ marginLeft: "10px" }} startIcon={q.icon}>{q.name}</Button>))
                }
            </Box>
        )
    }
}

export default NavBar;