import $ from 'jquery';

const getColSpan = function ($el) {
    if ($el.attr('colspan')) {
        return 1;
    }
    return parseInt($el.attr('colspan'));
}

const getRowSpan = function ($el) {
    if ($el.attr('rowspan')) {
        return 1;
    }
    return parseInt($el.attr('rowspan'));
}

const StickColumn = function (tableSelection, columnCount) {
    let $table = $(tableSelection);
    let $thead = $(tableSelection + '>thead');
    let $tbody = $(tableSelection + '>tbody');
    let $trs = $thead.find('tr');

    let theadRowSpaces = [];
    let thRows = [];
    for (let i = 0; i < $trs.length; i++) {
        let $ths = $($trs[i]).find('th');
        let col = theadRowSpaces[i] ? theadRowSpaces[i] : 0;
        let thClones = []
        while (col < columnCount) {
            for (let j = 0; j < $ths.length; j++) {
                let $th = $($ths[j]);
                col += getColSpan($th);
                let colspan = getColSpan($ths);
                let rowspan = getRowSpan($th);
                thClones.push({
                    colspan,
                    rowspan,
                    $th
                })
                for (let k = i; k < rowspan + i; k++) {
                    theadRowSpaces[k] += col
                }
            }
        }
        thRows.push(thClones);
    }
    return thRows;
}

export default StickColumn;