import requestHelper from "./requestHelper";
import dayjs from "dayjs";

const MAX_CHUNK = 300;

class DiemDanhService {

    constructor() {
        this.requestHelper = requestHelper;
    }

    getPhongBan = async (date) => {
        const dateStr = dayjs(date).format("YYYY-MM-DD");
        let phongbans = await requestHelper.get(`/api/diemDanhNgay/getPB/${dateStr}`);
        return phongbans;
    }

    getEnum = async () => {
        let enums = await requestHelper.get(`/api/getEnum`);
        return enums;
    }

    import = async (data) => {
        data.date = dayjs(data.date).format("YYYY-MM-DD");
        let baoCao = await requestHelper.post(`/api/diemDanhNgay/import`, data);
        return baoCao;
    }

    splitToChunk(array, max) {
        let sortedList = array.sort((a, b) => a.phongBanCode < b.phongBanCode)
        let listDatas = [];
        let newList = [];
        let newIndex = 0;
        for (let index = 0; index < sortedList.length; index++) {
            let preItem = sortedList[index - 1];
            let item = sortedList[index];
            newList.push(item);
            if ((preItem && newIndex > max && preItem.phongBanCode != item.phongBanCode) || (index == sortedList.length - 1)) {
                listDatas.push(newList);
                newList = [];
                newIndex = -1;
            }
            newIndex++;
        }
        return listDatas;
    }

    validate = async (data) => {
        let dataList = [data];
        data.date = dayjs(data.date).format("YYYY-MM-DD");
        if (data.list.length > MAX_CHUNK) {
            dataList = [];
            let listDatas = this.splitToChunk(data.list, MAX_CHUNK);

            for (let list of listDatas) {
                const eData = Object.assign({}, data);
                eData.list = list;
                dataList.push(eData);
            }
        }
        const baoCao = {};
        for (let eData of dataList) {
            let eBaoCao = await requestHelper.post(`/api/diemDanhNgay/validate`, eData);
            Object.assign(baoCao, eBaoCao);
        }
        return baoCao;
    }

    getLastedImport = async (searchInfo) => {
        let returnList = [];
        searchInfo.date = dayjs(searchInfo.date).format("YYYY-MM-DD");
        searchInfo.at = 0;
        let baoCao = {};
        do {
            baoCao = await requestHelper.post(`/api/diemDanhNgay/lastedImport`, searchInfo);
            searchInfo.at += baoCao.list.length;
            returnList = returnList.concat(baoCao.list);
        } while (searchInfo.at < baoCao.count && baoCao.list.length > 0)
        return returnList;
    }
}

export default DiemDanhService;