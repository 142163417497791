import React, { Component } from "react";
import BaoCaoNgayService from "../services/BaoCaoNgayService";
import HeaderTable from "../compoments/bcTable/Header";
import BodyTable from "../compoments/bcTable/Body";
import TreeUtils from "../utils/TreeUtils";
import dayjs from "dayjs";
import UserService from "../services/UserService";
import excelBuilder from "../utils/excelBuilder2";
import ExcelImport from "../utils/excelImport";
import ImportTable from "../compoments/bcTable/ImportTable";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import DownloadIcon from "@mui/icons-material/Download";
import { Box } from "@mui/material";

const userService = new UserService();
const baoCaoNgayService = new BaoCaoNgayService();

const ColorButton = styled(Button)(({ theme }) => ({
    fontFamily: ["Arial", "Helvetica", "sans-serif"].join(","),
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[700],
    "&:hover": {
        backgroundColor: green[900],
    },
}));
class ImportKetQuaPage extends Component {
    constructor(props) {
        super(props);
        this.body = React.createRef();
    }

    state = {
        baoCaoNgay: null,
        colArray: null,
        rowArray: null,
        selectedDate: dayjs(new Date()).format("YYYY-MM-DD"),
        user: null,
        exportType: 0,

        visbleImport: false,
        importData: { excelRowConfs: [] },
    };

    onChangeSelectedDate = (ev) => {
        let selectedDate = ev.target.value;
        this.changeDate(selectedDate);
        this.setState({ selectedDate });
    };

    changeDate = (selectedDate) => {
        userService.getCurrent().then((user) => {
            this.setState({ user });
            baoCaoNgayService.getByDate(selectedDate).then((bc) => {
                if (!bc.data) bc.data = {};
                const colArray = TreeUtils.getMaxLevelArray(bc.colConfig);
                this.columnCodes = colArray.map((q) => q.code);
                const rowArray = TreeUtils.flatTree(bc.rowConfig);
                TreeUtils.loadForAccount(rowArray, bc.rowConfig.forAccounts);
                this.setState({ rowArray, baoCaoNgay: bc, colArray });
            });
        });
    };

    componentDidMount() {
        this.changeDate(this.state.selectedDate);
    }

    saveData = () => {
        const updateDataWrap = {};
        updateDataWrap.id = this.state.baoCaoNgay._id;
        updateDataWrap.data = this.body.current.updateData;

        baoCaoNgayService
            .update(updateDataWrap)
            .then((bc) => {
                alert("Đã lưu kết quả");
            })
            .catch((ex) => {
                alert("Không thể lưu");
            });
    };

    download = () => {
        const data = this.body.current.state.data;
        let bcNgay = Object.assign({}, this.state.baoCaoNgay);
        bcNgay.data = data;
        excelBuilder.init(this.state.colArray, this.state.rowArray);
        const exportOption = { userName: this.state.user.name };
        if (this.state.exportType == 1) {
            exportOption.expands = this.body.current.state.expands;
        }
        const excel = excelBuilder.build(bcNgay, exportOption);
        excel.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "bcNgay" + bcNgay.createdDate + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    changeDownloadOptions = (ev) => {
        this.setState({ exportType: ev.target.value });
    };

    importData = (ev) => {
        let file = ev.currentTarget.files[0];
        if (!file) return;
        ExcelImport.init(
            this.state.bcNgay,
            this.state.colArray,
            this.state.rowArray
        );
        ExcelImport.importExcel(file, this.loadDataImport, this.state.user.name);
    };

    loadDataImport = (dataImport) => {
        this.setState({
            visbleImport: true,
            importData: dataImport,
        });
        document.getElementById("file_input").value = "";
    };

    saveImport = (data) => {
        let importData = Object.assign({}, this.state.importData);
        importData.id = this.state.baoCaoNgay._id;
        baoCaoNgayService
            .import(importData)
            .then((bc) => {
                this.changeDate(this.state.selectedDate);
                this.setState({ visbleImport: false });
                alert("Đã lưu kết quả");
            }).catch((ex) => {
                alert("Không thể lưu");
            })
    }

    closeImport = () => {
        this.setState({ visbleImport: false });
    }

    chooseFile = () => {
        document.getElementById('file_input').click();
    }

    render() {
        //  let userName = this.state.user ? this.state.user.name : "";
        return (
            <Box>
                <Box sx={{ width: '100%', typography: 'body1', padding: '10px', fontSize: '20px', textAlign: 'center', fontFamily: 'ui-rounded', fontWeight: '700' }}>KẾT QUẢ ĐIỂM DANH NHÂN SỰ</Box>
                <div>
                    <ImportTable
                        baoCaoNgay={this.state.baoCaoNgay}
                        columnCodes={this.columnCodes}
                        colConfArray={this.state.colArray}
                        rowConfArray={this.state.rowArray}
                        user={this.state.user}
                        visible={this.state.visbleImport}
                        importData={this.state.importData}
                        saveImport={this.saveImport}
                        onCloseClick={this.closeImport}
                    />
                    <div>
                        {/* <span>Nguời dùng : {userName}</span> */}
                        <span>Ngày nhập: </span>
                        <input
                            type="date"
                            max={dayjs(new Date()).format('YYYY-MM-DD')}
                            value={this.state.selectedDate}
                            onChange={this.onChangeSelectedDate} />
                    </div>
                    <br></br>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <select onChange={this.changeDownloadOptions}>
                                <option value="0">Tất cả</option>
                                <option value="1">Đang hiển thị</option>
                            </select>
                            <ColorButton sx={{ fontSize: '14px' }} onClick={this.download} variant="extended" size='' color="primary">
                                <DownloadIcon sx={{ marginRight: '2px' }} />Tải dữ liệu
                            </ColorButton>
                        </div>
                        <div>
                            <button onClick={this.chooseFile}>
                                Import
                            </button>
                            <input
                                style={{
                                    display: 'none'
                                }}
                                id="file_input"
                                type="file"
                                accept="excel/xls, excel/xlsx"
                                onChange={this.importData} />
                        </div>
                        <div>
                            <button
                                style={{
                                    marginLeft: '30px'
                                }}
                                onClick={this.saveData}>
                                Lưu
                            </button>
                        </div>
                    </div>
                    <table style={{
                        border: "solid 1px #000"
                    }} >
                        <HeaderTable baoCaoNgay={this.state.baoCaoNgay} />
                        <BodyTable
                            ref={this.body}
                            baoCaoNgay={this.state.baoCaoNgay}
                            columnCodes={this.columnCodes}
                            colConfArray={this.state.colArray}
                            rowConfArray={this.state.rowArray}
                            user={this.state.user} />
                    </table>

                </div>
            </Box>);
    }
}

export default ImportKetQuaPage;