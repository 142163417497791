const Cons = {
    SERVER_URL: "https://testnghiphep.thacoagri.com.vn/",
    //SERVER_URL: "http://localhost:3006",
    ColorLevels: [
        '#CAEDFB',
        '#CAEDFB',
        '#B6F05B',
        '#F1F1F1',
        '#90ee02',
        '#AAF255',
        '#C6F6D8',
        '#DEFABB',
    ],
    HeaderColor: '#CAEDFB',
    C_TYPE: ['TEXT', 'INT', 'PERCENT'],
    DIEM_DANH: ['Làm giờ hành chính', 'Làm Ca 2 + Ca 3', 'Công tác', 'Vắng mặt', 'Tăng ca'],
    HIEN_HUU: ["Đang làm việc", "Tuyển mới", "Nghỉ việc", "Điều chuyển đến", "Điều chuyển đi"],
    TONG_HOP_NAME: 'THACO AGRI',
    PERCENT_FORMAT: "0.0%",
    NUMBER_FORMAT: '#,###;[Red]-#,###'
}

export default Cons;