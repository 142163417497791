import ExcelJS from "exceljs";
import validator from 'validator';

const ImportDanhSach = {

    init: function (phongbans, configs) {
        this.phongbans = phongbans;
        this.configs = configs;
        this.configs.phongbans = phongbans.map(q => q.code + " - " + q.name);
    },

    widths: [10, 11, 25, 12, 40, 24, 28, 25, 15, 20, 17, 20, 10, 22, 10, 10],
    headerHeight: 252,
    rowHeight: 85,

    dataMap: [{
        name: "Số thứ tự",
        feild: "stt",
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FFFF0000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFA8D08D' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "MSNV \n (Y/c định dạng text)",
        feild: "empCode",
        fType: 'text',
        validate: function (value, rowData, allData, phongBans) {
            if (!value) return "Bắt buộc phải nhập mã số nhân viên";
            value = value.toString();
            const msnvs = allData.map(q => q.empCode);
            if (msnvs.includes(value)) {
                return "Mã số nhân viên trùng lặp trong file";
            }
            return null;
        },
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFA8D08D' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Họ tên nhân sự",
        feild: "profileName",
        validate: function (value) {
            if (!value) return "Yêu cầu nhập tên";
            return null;
        },
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFA8D08D' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Ngày vào làm",
        feild: "startDate",
        validate: (value, rowValue, allData, phongbans, others) => {
            if (!value) return;
            if (!value.getDay || !validator.isDate(value, { format: "DD-MM-YYYY" })) {
                return "Giá trị ngày vào làm phải có kiểu ngày tháng"
            }
        },
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFA8D08D' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        },
        rowStyle: {
            numFmt: 'd/m/yyyy'
        }
    },
    {
        name: "Chức danh công việc hiện hữu tại KLH/Cty trực thuộc THACO AGRI",
        feild: "PositionOnlyName",
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFA8D08D' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Công ty pháp nhân trực thuộc THACO AGRI",
        feild: "congTys",
        enum: "congTys",
        validate: (value, rowValue, allData, phongbans, others) => {
            if (!value) {
                return "Yêu cầu nhập công ty";
            }
        },
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "KHL/TCT",
        feild: "klh",
        out: true,
        dynamic: true,
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "VP/XN/Công Ty",
        feild: "xn",
        out: true,
        dynamic: true,
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Phòng ban",
        feild: "phongBanCode",
        enum: "phongbans",
        validate: (value, rowValue, allData, phongbans, others) => {
            if (!value) {
                return "Yêu cầu nhập phòng ban";
            }
            if (!others.phongbans.includes(value)) {
                return "Phòng ban không đúng quy chuẩn";
            }
        },
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Thành phần nhân sự",
        feild: "tp",
        out: true,
        dynamic: true,
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "CHỨC DANH \n THEO THÀNH PHẦN THỐNG KÊ \n (Quy chuẩn)",
        feild: "chucVus",
        enum: "chucVus",
        validate: (value, rowValue, allData, phongbans, others) => {
            if (!value) {
                return "Yêu cầu nhập chức danh";
            }
            if (!others.chucVus.includes(value)) {
                return "Chức danh không đúng quy chuẩn";
            }
        },
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Hiệu hữu",
        feild: "status",
        validate: function (value, rowValue, allData, phongbans, others) {
            if (!others.status.includes(value)) {
                return "Hiện hữu không đúng với danh sách chọn"
            }
        },
        enum: "status",
        bcHidden: true,
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFE598' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Lý do nghỉ việc thực tế",
        feild: "reasonQuit",
        enum: "reasonQuit",
        validate: function (value, rowValue, allData, phongbans, others) {
            if (rowValue.status == others.status[2] && !value) {
                return "Yêu cầu nhập lý do nghỉ việc";
            }
            if (others.reasonQuit.filter(q => q != value).length == 0) {
                return "lý do nghỉ việc không tồn tại";
            }
        },
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFE598' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Điểm danh",
        feild: "typeOfDate",
        validate: function (value, rowValue, allData, phongbans, others) {
            if (!value && (rowValue.status === others.status[2] || rowValue.status === others.status[4])) {
                return null;
            }
            if (!others.typeOfDate.includes(value)) {
                return "Điểm danh không đúng với danh sách chọn"
            }
        },
        enum: "typeOfDate",
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFC1F0C8' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Lý do vắng mặt",
        feild: "reasonOnLeave",
        validate: function (value, rowValue, allData, phongbans, others) {
            if (!value) {
                if (rowValue.typeOfDate === others.typeOfDate[3]) {
                    return "Loại điểm danh vắng mặt phải có lý do nghỉ";
                }
                return;
            }
            if (!others.reasonOnLeave.includes(value)) {
                return "Lý do nghỉ không đúng với danh sách chọn"
            }
        },
        enum: 'reasonOnLeave',
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFC1F0C8' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Tăng ca",
        feild: "overTime",
        validate: function (value, rowValue, allData, phongbans, others) {
            if (!value) return;
            if (!others.overTime.includes(value)) {
                return "Tăng ca không đúng danh sách chọn"
            }
        },
        enum: 'overTime',
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFC0E6F5' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    },
    {
        name: "Ghi chú",
        feild: "note",
        style: {
            font: {
                name: 'Times New Roman',
                size: 12,
                bold: true,
                color: { argb: 'FF000000' }
            },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF92D050' },
            },
            alignment: {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
            },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    }
    ],

    importExcel(fileRes, loadFunc, startRow = 1) {
        if (!fileRes) return;
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(fileRes);
        const dataMap = this.dataMap.filter(q => !q.out)
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer).then(workbook => {
                const sheet = workbook.worksheets[0];
                const importData = [];
                const errors = {};
                let index = 0;
                const phongbans = this.phongbans.map(q => q.code + " - " + q.name);
                for (let row of sheet._rows) {
                    if (index >= startRow && row.values[2]) {
                        const empInfo = {}
                        for (let i = 0; i < dataMap.length; i++) {
                            let value = row.values[i + 1];
                            if (dataMap[i].fType === 'text') {
                                value = value ? "" : value.toString().trim();
                            }
                            empInfo[dataMap[i].feild] = row.values[i + 1];
                            if (dataMap[i].validate) {
                                let error = "";
                                error = dataMap[i].validate(row.values[i + 1], empInfo, importData, phongbans, this.configs);
                                if (error) {
                                    errors[index + "_" + i] = error;
                                }
                            }
                        }
                        importData.push(empInfo);
                    };
                    index++;
                }

                loadFunc({
                    importData,
                    errors
                });
            })
        }
    },
}

export default ImportDanhSach;