import ExcelJS from "exceljs";
import TreeUtils from "./TreeUtils";
import Cons from "./Cons";
import ImportDanhSach from "./ImportDanhSach";
import dayjs from "dayjs";

const colors = ["F1F1F1", "FFFFFF"];

const importFileExcelBuilder = {

    findLevel(code, phongBans, level, type = 0) {
        let tempCode = code;
        if (type == 1) {
            tempCode = phongBans.filter(q => q.name == code);
            tempCode = tempCode[0].code;
        }
        while (tempCode.length > 0) {
            let phongBan = phongBans.filter(q => q.code == tempCode);
            if (!phongBan || phongBan.length == 0) {
                return null;
            }
            phongBan = phongBan[0];
            if (type == 0 && phongBan.level == level) {
                return phongBan;
            } else if (type == 1 && phongBan.code.split('.').length == level) {
                return phongBan;
            }
            tempCode = tempCode.split('.');
            tempCode.pop();
            tempCode = tempCode.join('.');
        }
        return null;
    },

    getDynamic(propName, rowValue) {
        let value = null;
        switch (propName) {
            case "klh":
                value = this.findLevel(rowValue.phongBanCode.split(' - ')[0], this.rowConfigs, 1);
                break;
            case "xn":
                value = this.findLevel(rowValue.phongBanCode.split(' - ')[0], this.rowConfigs, 2);
                break;
            case "tp":
                value = this.findLevel(rowValue.chucVus, this.capDoNs, 2, 1);
                break;
            default:
                value = null;
        }
        if (value) return value.name;
    },

    /**
     * Tạo file import
     * @param {BaoCaoNgayModel} baoCaoNgayModel Data and config
     */
    build(capDo, preImportData, errorData = null, workbook = null, type = 0) {
        if (capDo) {
            this.rowConfigs = TreeUtils.flatTree(capDo.rowConfig);
            this.capDoNs = TreeUtils.flatTree(capDo.capDoNs);
        }
        this.Headers = ImportDanhSach.dataMap.map(q => q.name);
        workbook = workbook ? workbook : new ExcelJS.Workbook();

        workbook.creator = 'Phan Van Binh';
        workbook.created = new Date();
        workbook.modified = new Date();

        const sheet = workbook.addWorksheet('FileImport');
        sheet.views = [
            { state: 'frozen', xSplit: 3, ySplit: 1 }
        ];

        let danhMucName = 'Danh Mục'
        const sheet2 = workbook.addWorksheet(danhMucName);
        this.makeHeader(sheet, type);
        this.setWidths(sheet, ImportDanhSach.widths);

        if (!type) {
            this.setValidateList(sheet, sheet2);
        }

        let importData = preImportData;
        if (!errorData) {
            importData = this.makeNewData(preImportData, type);
        }
        this.makeBody(sheet, importData, errorData, type);
        return workbook;
    },

    makeNewData(preImportData, type = 0) {
        const importData = preImportData.filter(q => q.status !== Cons.HIEN_HUU[2] && q.status !== Cons.HIEN_HUU[4]);
        for (const data of importData) {
            if (data.status !== Cons.HIEN_HUU[1] || data.status !== Cons.HIEN_HUU[3]) {
                data.status = Cons.HIEN_HUU[0];
            }
        }
        if (type == 1) {
            for (let rowData of preImportData) {
                const dynamicProps = ImportDanhSach.dataMap.filter(q => q.dynamic);
                for (let dynamicProp of dynamicProps) {
                    rowData[dynamicProp.feild] = this.getDynamic(dynamicProp.feild, rowData);
                }
            }
        }
        return importData;
    },

    makeBody(sheet, preImportData, errorData = null, type = 0) {
        let index = 1;
        let dataMap = ImportDanhSach.dataMap;
        if (type == 0) {
            dataMap = ImportDanhSach.dataMap.filter(q => !q.out);
        }
        for (let nv of preImportData) {
            nv.stt = index;
            this.makeRowData(sheet, nv, index + 1, errorData, dataMap);
            index++;
        }
    },

    makeRowData(sheet, nv, rowIndex, errorData, dataMap) {
        let colName = 'A';
        let errorMessages = [];
        let colIndex = 0;
        for (let col of dataMap) {
            let errorMessage = null;
            if (errorData) {
                errorMessage = errorData[(rowIndex - 1) + "_" + colIndex];
                if (dataMap.indexOf(col) == 1) {
                    errorMessage = errorData[nv[col.feild]];
                }
            }
            const cellName = colName + rowIndex;
            const cell = sheet.getCell(cellName);
            cell.value = nv[col.feild];
            if (col.feild.toLowerCase().includes('date') && dayjs(cell.value).isValid()) {
                cell.value = dayjs(cell.value).toDate();
            }
            this.setStyle(cell, false, col);
            if (errorMessage) {
                errorMessages.push(errorMessage);
                this.setStyle(cell, 3, col);
            }
            colName = TreeUtils.getShiftColLabel(colName, 1);
            colIndex++;
        }
        if (errorMessages.length > 0) {
            const cell = sheet.getCell(colName + rowIndex);
            cell.value = errorMessages.join(', ');
        }
    },

    makeHeader(sheet, type = 0) {
        let columnName = 'A';
        let dataMap = ImportDanhSach.dataMap;
        if (type == 0) {
            dataMap = dataMap.filter(q => !q.out);
        }
        for (let col of dataMap) {
            let cellName = columnName + 1;
            const cell = sheet.getCell(cellName);
            cell.value = col.name;
            columnName = TreeUtils.getShiftColLabel(columnName, 1);
            Object.assign(cell, col.style);
        }
    },

    setValidateList(sheet, enumSheet) {
        let listCol = ImportDanhSach.dataMap.filter(q => q.enum);
        let colConfigs = ImportDanhSach.configs;
        const refers = [];
        let startCol = 'A';
        for (let col of listCol) {
            const refer = this.makeReferList(enumSheet, colConfigs[col.enum], startCol, col.name);
            startCol = TreeUtils.getShiftColLabel(startCol, 1);
            this.setValidateCol(sheet, col.name, 2, ["='" + enumSheet.name + "'!" + refer], true);
        }
    },

    makeReferList(sheet, referList, startCol, refName) {
        let cell = sheet.getCell(startCol + "1");
        cell.value = refName;
        this.setStyle(cell, true);
        let cellIndex = 2;
        for (const name of referList) {
            cell = sheet.getCell(startCol + cellIndex);
            cell.value = name;
            this.setStyle(cell, false);
            cellIndex++;
        }
        return "$" + startCol + "$2:" + "$" + startCol + "$" + (cellIndex - 1);
    },

    setStyle(cell, isHeader, col = null) {
        if (isHeader == 3) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF0000' },
            };
            return;
        }
        let color = isHeader ? colors[0] : colors[1];
        cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: color },
        };
        if (isHeader) {
            cell.alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
        } else {
            cell.alignment = {
                vertical: 'middle',
                horizontal: 'left'
            };
        }
        cell.font = {
            name: 'Times New Roman',
            size: 12,
        }
        if (col && col.rowStyle) {
            Object.assign(cell, col.rowStyle)
        }
    },

    setWidths(sheet, widths) {
        let index = 1;
        for (let width of widths) {
            let column = sheet.getColumn(index);
            column.width = width;
            index++;
        }
    },

    setValidateCol(sheet, colName, starIndex, referList, isString = false) {
        let startCol = this.Headers.indexOf(colName);
        startCol = TreeUtils.getShiftColLabel('A', startCol);
        let formulae = !isString ? ['"' + referList.join(',') + '"'] : referList;
        sheet.dataValidations.add(startCol + starIndex + ':' + startCol + '9999', {
            type: 'list',
            allowBlank: false,
            formulae,
            showErrorMessage: true,
            errorStyle: 'error',
            error: 'The value Valid',
        });
    },
}

export default importFileExcelBuilder;