import ExcelJS from "exceljs";
import TreeUtils from "./TreeUtils";
import Cons from "./Cons";
const dayjs = require('dayjs');

const sumColorLevels = ['FF47D359', 'FFF2F2F2', 'FFFFFFFF', 'FFFFFFFF'];
const oddColorLevels = ['FFD1F1DA', 'FF92D050', 'FFFFFFFF', 'FFFFFFFF'];
const eventColorLevels = ['FFB5E3E8', 'FF92D050', 'FFFFFFFF', 'FFFFFFFF'];

const excelBuilder = {

    init(colConfArray, rowConfArray, data) {
        this.rowConfArray = rowConfArray;
        this.colConfArray = colConfArray;
        this.data = data ? data : {};

        TreeUtils.addExcelColumnName(this.colConfArray, 'C');
    },

    /**
     * Tạo file bao cao
     * @param {BaoCaoNgayModel} baoCaoNgayModel Data and config
     */
    build(baoCaoNgay, exportOption = {}) {
        baoCaoNgay.viewData = baoCaoNgay.viewData ? baoCaoNgay.viewData : {};
        const workbook = new ExcelJS.Workbook();

        workbook.creator = 'Phan Van Binh';
        workbook.created = new Date();
        workbook.modified = new Date();

        if (exportOption.exportType == 1) {
            const sheet = workbook.addWorksheet('Bao Cao');
            this.makeTitle(sheet, baoCaoNgay.titles[0], baoCaoNgay);
            let startRow = baoCaoNgay.titles[0].maxRow + 1;
            sheet.views = [
                { state: 'frozen', xSplit: 2, ySplit: 10 }
            ];

            this.makeHeader(baoCaoNgay, sheet, startRow);
            const rowMap = this.makeBody(baoCaoNgay, sheet, exportOption, startRow);
            this.setSizeTable(baoCaoNgay, sheet, startRow);
        } else {
            const sheet = workbook.addWorksheet('Bao Cao');
            this.makeTitle(sheet, baoCaoNgay.titles[0], baoCaoNgay);
            let startRow = baoCaoNgay.titles[0].maxRow + 1;
            sheet.views = [
                { state: 'frozen', xSplit: 2, ySplit: 10 }
            ];

            this.makeHeader(baoCaoNgay, sheet, startRow);
            //let tongHopLength = this.rowConfArray.filter(q => q.code.startsWith('0')).length;
            const rowMap = this.makeBody(baoCaoNgay, sheet, exportOption, 2 + startRow);
            this.makeTongHopRows(baoCaoNgay, sheet, rowMap, startRow);
            this.setSizeTable(baoCaoNgay, sheet, startRow);
        }

        return workbook;
    },

    buildSheetChild(workbook, baoCaoNgay, node, nodeCode) {
        let stt = parseInt(nodeCode) + 1;
        let sheetName = stt + ". " + node.name;
        const sheet = workbook.addWorksheet(sheetName);
        this.makeHeader(baoCaoNgay, sheet);

        const rowMap = this.makeBody(baoCaoNgay, sheet, { nodeCode });
    },

    makeTongHopRows(baoCaoNgay, sheet, rowMap, StartIndex) {
        let rowConf = Object.assign({}, this.rowConfArray[0]);
        rowConf.name = "TỔNG CỘNG";
        rowConf.stt = " ";
        rowConf.sumIndexes = this.rowConfArray.filter(q => q.code.split('.').length == 1).map(q => rowMap[q.code]);
        rowConf.bgColor = 'FFFFFF00';

        let rowConf1 = Object.assign({}, this.rowConfArray[0]);
        rowConf1.name = "THEO ĐƠN VỊ";
        rowConf1.sumIndexes = this.rowConfArray.filter(q => q.code.split('.').length == 1).map(q => rowMap[q.code]);
        rowConf1.bgColor = 'FFFDD868';
        rowConf1.stt = "I";

        let rowConfArray = [rowConf, rowConf1];
        this.makeBody(baoCaoNgay, sheet, { rowConfArray }, StartIndex, sumColorLevels);

        let rowConf3 = Object.assign({}, this.rowConfArray[0]);
        rowConf3.sumIndexes = this.rowConfArray.filter(q => q.code.split('.').length == 1).map(q => rowMap[q.code]);
        rowConf3.name = "THÀNH PHẦN THEO NHÂN SỰ";
        rowConf3.sumIndexes = this.rowConfArray.filter(q => q.code.split('.').length == 1).map(q => rowMap[q.code]);
        rowConf3.bgColor = 'FFFDD868';
        rowConf3.stt = "II";
        rowConfArray = [rowConf3];
        let copyRows = this.rowConfArray.filter(q => q.code.startsWith('0.'));
        for (let sumRowConfCP of copyRows) {
            let rowConf = Object.assign({}, sumRowConfCP);
            rowConfArray.push(rowConf);
            if (!rowConf.hasChild) {
                rowConf.sumIndexes = this.rowConfArray.filter(q => q.name == rowConf.name).map(q => rowMap[q.code]);
            } else {
                rowConf.sumIndexes = this.rowConfArray.filter(q => q.name == rowConf.name).map(q => rowMap[q.code]);
            }
        }
        this.makeBody(baoCaoNgay, sheet, { rowConfArray }, StartIndex + 2 + Object.keys(rowMap).length, sumColorLevels);
    },

    /**
     * Select a range of cells
     * @param  {string}   startCell - Range start cell (top-left)
     * @param  {string}   endCell   - Range end cell (bottom-right)
     * @return {Object[]}           - Selected cells
     */
    selectRange: (sheet, startCell, endCell) => {
        const [endCellColumn, endRow] = endCell.split(":", 2);
        const [startCellColumn, startRow] = startCell.split(":", 2);

        let endColumn = sheet.getColumn(endCellColumn);
        let startColumn = sheet.getColumn(startCellColumn);

        if (!endColumn) throw new Error("End column not found");
        if (!startColumn) throw new Error("Start column not found");

        endColumn = endColumn._number;
        startColumn = startColumn._number;

        const cells = [];
        for (let y = startRow; y <= endRow; y++) {
            const row = sheet.getRow(y);

            for (let x = startColumn; x <= endColumn; x++) {
                cells.push(row.getCell(x));
            }
        }

        return cells;
    },

    makeTitle(sheet, title, baoCao) {
        for (let cellInfo of title.cells) {
            let cell = null;
            if (toString.call(cellInfo.range) === "[object Array]") {
                cell = this.selectRange(sheet, cellInfo.range[0], cellInfo.range[1]);
            } else {
                if (cellInfo.range.includes(":")) {
                    let range = cellInfo.range.split(":");
                    cell = sheet.getCell(range[0]);
                    if (cellInfo.merge) {
                        sheet.mergeCells(cellInfo.range);
                    }
                } else {
                    cell = sheet.getCell(cellInfo.range);
                }
            }
            if (cellInfo.content) {
                if (cellInfo.evalValue) {
                    cell.value = eval(cellInfo.content);
                } else {
                    cell.value = cellInfo.content;
                }
            }
            if (cell.length) {
                for (let c of cell) {
                    Object.assign(c, cellInfo.style);
                }
            } else {
                Object.assign(cell, cellInfo.style);
            }
        }
    },

    makeBody(bcNgay, sheet, exportOption, startRow = 1, colorLevels = null) {
        sheet.properties.outlineProperties = {
            summaryBelow: false,
            summaryRight: false,
        };
        const rowMap = {};
        let rowExcelIndex = bcNgay.colConfig.maxRowLevel + startRow;
        sheet.properties.outlineLevelRow = 4;

        let rowConfArray = this.rowConfArray;
        if (exportOption.rowConfArray) {
            rowConfArray = exportOption.rowConfArray;
        }
        TreeUtils.addRowName(rowConfArray, rowExcelIndex, exportOption);
        const expand = exportOption.expands ? 2 : true;
        let rowConfIndex = 0;
        let changeColorLevel = colorLevels == null;
        for (let rowConf of rowConfArray) {
            if (TreeUtils.getVisible(rowConfArray, rowConf, exportOption)) {
                let rowCodes = rowConf.code.split(".");
                if (changeColorLevel) {
                    colorLevels = parseInt(rowCodes[0]) % 2 == 0 ? oddColorLevels : eventColorLevels;
                }
                let row = sheet.getRow(rowExcelIndex);
                row.outlineLevel = rowCodes.length - 1;
                if (!changeColorLevel) {
                    if (row.outlineLevel > 1) {
                        row.hidden = true;
                    }
                } else {
                    if (row.outlineLevel > 0) {
                        row.hidden = true;
                    }
                }

                let startColName = 'A';
                sheet.getCell(startColName + rowExcelIndex).value = this.getSTT(rowConf);
                this.setStyle(sheet.getCell(startColName + rowExcelIndex), rowConf, expand, colorLevels, { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } });
                startColName = TreeUtils.getShiftColLabel(startColName, 1);
                sheet.getCell(startColName + rowExcelIndex).value = rowConf.name;
                this.setStyle(sheet.getCell(startColName + rowExcelIndex), rowConf, expand, colorLevels);

                for (const colConf of this.colConfArray) {
                    this.setCellData(sheet, colConf, rowConf, this.data, exportOption, colorLevels);
                }
                rowMap[rowConf.code] = rowExcelIndex;
                rowExcelIndex++;
            }
            rowConfIndex++;
        }
        return rowMap;
    },

    getSTT(rowConf) {
        if (rowConf.stt) {
            return rowConf.stt;
        }
        let stt = rowConf.code.split('.');
        stt = stt[stt.length - 1];
        return (parseInt(stt) + 1).toString();
    },

    setSizeTable(baoCaoNgay, sheet, startRow = 1) {
        for (let i = 1; i < baoCaoNgay.colConfig.widths.length + 1; i++) {
            let column = sheet.getColumn(i);
            column.width = baoCaoNgay.colConfig.widths[i - 1];
        }
        for (let i = startRow; i < baoCaoNgay.colConfig.heights.length + startRow; i++) {
            let row = sheet.getRow(i);
            row.height = baoCaoNgay.colConfig.heights[i - startRow];
        }
    },

    makeHeader(baoCaoNgay, sheet, startRow = 1) {
        let shiftCell = TreeUtils.getShiftCellLabel('A', startRow, 1, baoCaoNgay.colConfig.maxRowLevel);
        let sttCell = "A" + startRow + ":" + shiftCell.toColName + shiftCell.toRowName;
        sheet.mergeCells(sttCell);
        sheet.getCell("A" + startRow).value = "STT";
        this.setStyle(sheet.getCell("A" + startRow));

        shiftCell = TreeUtils.getShiftCellLabel('B', startRow, 1, baoCaoNgay.colConfig.maxRowLevel);
        sttCell = "B" + startRow + ":" + shiftCell.toColName + shiftCell.toRowName;
        sheet.mergeCells(sttCell);
        let cell = sheet.getCell("B" + startRow);
        cell.value = "Tên đơn vị";
        this.setStyle(cell);

        this.makeHeaderCell(baoCaoNgay.colConfig, sheet, "C", startRow);
    },

    makeHeaderCell(colConf, sheet, colName = 'A', rowName = 1) {
        const cell = sheet.getCell(colName + rowName);
        if (colConf.name) {
            let shiftCell = TreeUtils.getShiftCellLabel(colName, rowName, colConf.room[0], colConf.room[1]);
            const cellMerge = colName + rowName + ":" + shiftCell.toColName + shiftCell.toRowName;
            console.log("cell : " + cellMerge + " -- " + colConf.name);
            sheet.mergeCells(cellMerge);
            cell.value = colConf.name;
            this.setStyle(cell);
            Object.assign(cell, colConf.style);
        } else {
            colConf.room = [0, 0];
        }
        if (!colConf.child || colConf.child.length == 0) return;

        let shiftColName = colName;
        for (const childColConf of colConf.child) {
            this.makeHeaderCell(childColConf, sheet, shiftColName, rowName + colConf.room[1]);
            shiftColName = TreeUtils.getShiftColLabel(shiftColName, childColConf.room[0]);
        }
    },

    /**
     * 
     * @param {*} cell 
     * @param {*} rowConfig 
     * @param {*} For null is default, 1 is title, 2 is expands
     */
    setStyle(cell, rowConfig = null, For = null, ColorLevels = null, other) {
        let color = Cons.HeaderColor;
        if (rowConfig) {
            color = ColorLevels[rowConfig.code.split('.').length - 1];
        }
        if (!color) {
            color = 'FFFFFF';
        }
        color = color.replace("#", "");
        color = color.toUpperCase();
        let bold = false;
        if ((rowConfig && !rowConfig.hasChild) || For == 2) {
            color = "FFFFFFFF";
        }
        if (rowConfig && rowConfig.bgColor) {
            color = rowConfig.bgColor
        }
        if (rowConfig && rowConfig.hasChild) {
            bold = true;
        }
        if (For !== 1) {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color },
            };
        }
        if (!rowConfig) {
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            cell.font = {
                name: 'Times New Roman',
                size: 12,
                bold: false,
                color: { argb: 'FFFFFFFF' }
            };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF0F9ED5' },
            }
        }
        if (!cell.font) {
            cell.font = { bold };
        }
        cell.font.name = 'Times New Roman';
        if (other) {
            Object.assign(cell, other)
        }
    },

    setCellData(sheet, colConfig, rowConfig, data, exportOption, colorLevels) {
        const cell = sheet.getCell(colConfig.excelCol + rowConfig.excelRow);
        const expand = exportOption.expands ? 2 : true;
        this.setStyle(cell, rowConfig, expand, colorLevels);
        cell.numFmt = Cons.NUMBER_FORMAT;
        if (exportOption.expands) {
            cell.value = data[colConfig.key + " " + rowConfig.key];
            if (colConfig.func && colConfig.func.startsWith('p')) {
                cell.numFmt = Cons.PERCENT_FORMAT;
            }
            return;
        }
        if (rowConfig.hasChild || rowConfig.sumIndexes) {
            if (colConfig.cType === Cons.C_TYPE[0]) {
                cell.value = data[colConfig.key + " " + rowConfig.key];
                return;
            }
            this.setCellSumof(cell, colConfig, rowConfig);
        }
        if (colConfig.func && colConfig.func.startsWith('p')) {
            this.setColFunc(cell, colConfig, rowConfig, data);
        }
        if (!cell.value && colConfig.func) {
            this.setColFunc(cell, colConfig, rowConfig, data);
        }
        if (!cell.value) {
            cell.value = data[colConfig.key + " " + rowConfig.key];
        }
    },

    setCellSumof(cell, colConfig, rowConfig, childs = null) {
        if (!childs) {
            childs = TreeUtils.findAllChild(this.rowConfArray, rowConfig.code);
        }
        if (rowConfig.sumIndexes) {
            childs = rowConfig.sumIndexes.map(q => { return { excelRow: q } });
        }
        let formula = [];
        for (let child of childs) {
            formula.push(colConfig.excelCol + child.excelRow);
        }
        formula = formula.join("+");
        cell.value = { formula };
    },

    setColFunc(cell, colConfig, rowConf, data) {
        let isPercent = colConfig.func.includes("p");
        const relateColums = this.colConfArray.filter(q => colConfig.func.includes("{" + q.code + "}"));
        let formula = colConfig.func + "";
        if (isPercent) {
            if (!rowConf.key) return;
            formula = colConfig.func.replace("p", "");
            cell.numFmt = Cons.PERCENT_FORMAT;
        }
        for (let column of relateColums) {
            let cellName = column.excelCol + rowConf.excelRow;
            formula = formula.replace("{" + column.code + "}", cellName);
        }
        cell.value = { formula };
    },
}

export default excelBuilder;