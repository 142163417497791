import React, { Component } from 'react';
import BaoCaoNgayService from '../services/BaoCaoNgayService';
import DiemDanhService from '../services/DiemDanhService';
import HeaderTable from '../compoments/bcTable/Header';
import BodyBaoCao from '../compoments/bcTable/BodyBaoCao';
import TreeUtils from '../utils/TreeUtils';
import dayjs from 'dayjs';
import UserService from '../services/UserService';
import excelBuilder from '../utils/excelBuilder2';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import "../styles/ThacoTable.css"
import { Box, Stack } from '@mui/material';
import importFileExcelBuilder from '../utils/createImportFile';
import ImportDanhSach from '../utils/ImportDanhSach';
import StickColumn from '../utils/StickColumn';

const userService = new UserService();
const baoCaoNgayService = new BaoCaoNgayService();
const diemDanhService = new DiemDanhService();

const ColorButton = styled(Button)(({ theme }) => ({

    fontFamily: [
        'Arial', 'Helvetica', 'sans-serif'
    ].join(','),
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[700],
    '&:hover': {
        backgroundColor: green[900],
    },
}));
class ThongKeDiemDanhPage extends Component {

    constructor(props) {
        super(props);
        this.body = React.createRef();
    }

    state = {
        baoCaoNgay: null,
        colArray: null,
        rowArray: null,
        selectedDate: dayjs(new Date).format('YYYY-MM-DD'),
        user: null,

        visbleImport: false,
        exportType: 0,
    }

    onChangeSelectedDate = (ev) => {
        let selectedDate = ev.target.value;
        this.changeDate(selectedDate);
        this.setState({ selectedDate });
    }

    changeDate = (selectedDate) => {
        diemDanhService.getEnum().then((configs) => {
            diemDanhService.getPhongBan(selectedDate).then((phongbans) => {
                this.setState({ phongBan: phongbans[0].key })
                this.setState({ phongbans });
                ImportDanhSach.init(phongbans, configs);
            })
        });
        userService.getCurrent().then((user) => {
            this.setState({ user });
            baoCaoNgayService.getByDate(selectedDate).then((bc) => {
                if (!bc.data) bc.data = {};
                const colArray = TreeUtils.getMaxLevelArray(bc.colConfig);
                this.columnCodes = colArray.map(q => q.code);
                const rowArray = TreeUtils.flatTree(bc.rowConfig);
                TreeUtils.loadForAccount(rowArray, bc.rowConfig.forAccounts);
                this.setState({ rowArray, baoCaoNgay: bc, colArray });
            });
        });
    }

    componentDidMount() {
        this.changeDate(this.state.selectedDate);

        const stickTable = StickColumn('table.thaco', 3);
        console.log(stickTable.length);
    }

    download = (exConf = {}) => {
        exConf = { exportType: this.state.exportType };
        let bcNgay = Object.assign({}, this.state.baoCaoNgay);

        if (exConf.exportType == 0) {
            let chucVuRow = TreeUtils.flatTree(this.state.baoCaoNgay.viewRowConf);
            TreeUtils.loadForAccount(chucVuRow, this.state.baoCaoNgay.viewRowConf.forAccounts);
            excelBuilder.init(this.state.colArray, chucVuRow, this.state.baoCaoNgay.viewData);
        } else {
            excelBuilder.init(this.state.colArray, this.state.rowArray, this.state.baoCaoNgay.data);
        }

        const exportOption = { userName: this.state.user.name };
        Object.assign(exportOption, exConf);
        let excel = excelBuilder.build(bcNgay, exportOption);

        diemDanhService.getLastedImport({ date: this.state.selectedDate, sameDate: true, allRole: true }).then((lastedImport) => {
            excel = importFileExcelBuilder.build({ rowConfig: this.state.baoCaoNgay.rowConfig, capDoNs: this.state.baoCaoNgay.viewRowConf }, lastedImport, null, excel, 1);
            excel.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer]);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = "bcNgay" + dayjs(new Date()).format("_DD_MM_YYYY") + ".xlsx";
                a.click();
                window.URL.revokeObjectURL(url);
            })
        });
    }

    saveData = () => {
        const updateDataWrap = {};
        updateDataWrap.id = this.state.baoCaoNgay._id;
        updateDataWrap.data = this.body.current.updateData;

        baoCaoNgayService.update(updateDataWrap).then((bc) => {
            alert("Đã lưu");
        }).catch((ex) => {
            alert("Không thể lưu");
        })
    }

    render() {
        // let userName = this.state.user ? this.state.user.name : "";
        return (
            <Box>
                <div class="table-wrap" style={{ height: 'calc(100vh - 70px - 64px)', overflowY: 'scroll' }}>
                    <table className='thaco' style={{
                        border: "solid 1px #000"
                    }} >
                        <HeaderTable
                            toggleAll={(expand) => { this.setState({ toggleAll: expand }) }}
                            baoCaoNgay={this.state.baoCaoNgay} />
                        <BodyBaoCao
                            ref={this.body}
                            baoCaoNgay={this.state.baoCaoNgay}
                            columnCodes={this.columnCodes}
                            colConfArray={this.state.colArray}
                            rowConfArray={this.state.rowArray}
                            toggleAll={this.state.toggleAll}
                            user={this.state.user} />
                    </table>
                </div>
                <Stack direction='row' sx={{ background: 'white', boxShadow: 'rgba(17, 12, 46, 0.15) 0px -48px 100px 0px;', position: 'fixed', justifyContent: 'space-between', bottom: '0px', left: '0px', right: '0px', padding: '1rem' }}>
                    <Box>
                        <input
                            type="date"
                            max={dayjs(new Date()).format('YYYY-MM-DD')}
                            value={this.state.selectedDate}
                            onChange={this.onChangeSelectedDate} />
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                        <select name='loaiDiemDanh' value={this.state.exportType} onChange={(ev) => { this.setState({ exportType: ev.target.value }) }}>
                            <option value={0}>Báo cáo theo TPNS</option>
                            <option value={1}>Báo cáo theo phòng ban</option>
                        </select>
                        <ColorButton sx={{ fontSize: '14px' }} onClick={this.download} variant="extended" size='' color="primary">
                            <DownloadIcon sx={{ marginRight: '2px' }} />Tải báo cáo
                        </ColorButton>
                    </Box>
                </Stack>
            </Box >);
    }
}

export default ThongKeDiemDanhPage;